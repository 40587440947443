<template>
  <div>
    <!-- Filters -->
    <!-- <users-list-filters @filterUsers="fetchUsersHandler" /> -->

    <b-card no-body class="mb-0">
      <!-- Search -->
      <div
        class="
          m-2
          d-flex
          align-items-center
          justify-content-end
          flex-column flex-md-row
        "
      >
        <b-form-input
          v-model="searchQuery"
          class="search-input d-inline-block mr-1 w-auto mb-2 mb-md-0"
          :placeholder="
            $t('search_by_name_email_phone') || 'Search by name, email or phone'
          "
          autocomplete="off"
          @input="searchUsersHandler"
        />
        <b-link
          v-if="userCan('judges-add')"
          data-test="add-new-btn"
          class="btn btn-primary mx-1 mb-2 mb-md-0"
          to="/add-new-judge"
        >
          <span class="text-nowrap">
            {{ $t("add_new_judge") || "Add New judge" }}
          </span>
        </b-link>
      </div>
      <b-overlay :show="isLoading" rounded>
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="judges"
          responsive
          striped
          hover
          :fields="tableColumns"
          show-empty
          :empty-text="
            $t('no_matching_records_found') || 'No matching records found'
          "
        >
          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="
                    typeof data.item.image === 'string' ? data.item.image : null
                  "
                  :to="`/user/${data.item.uuid}`"
                  variant="primary"
                  :text="avatarText(data.item.name)"
                  target="blank"
                />
              </template>
              <b-link
                :to="`/user/${data.item.uuid}`"
                target="blank"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
              <small class="text-muted">{{ data.item.email }}</small>
            </b-media>
          </template>

          <!-- Column: Role -->
          <template #cell(roles)="data">
            <div class="">
              <b-badge
                v-for="(role, key) in data.item.roles"
                :key="role.uuid || key"
                class="mi-end-1"
                :class="data.item.roles.length > 2 ? 'mb-end-1' : ''"
              >
                {{ role.name }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Role -->
          <template #cell(actor)="data">
            <div class="text-nowrap">
              <b-badge class="mb-1">
                {{ getJudgeActor(data.item.actor) }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <!-- :variant="`light-${resolveUserStatusVariant(data.item.status)}`" -->
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(
                data.item.is_active ? 'active' : 'inactive'
              )}`"
              class="text-capitalize"
            >
              {{
                data.item.is_active
                  ? $t("active") || "Active"
                  : $t("inactive") || "Inactive"
              }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="userCan('judges-access')"
                @click="openUserDetailsModal(data.item)"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">
                  {{ $t("details") || "Details" }}
                </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userCan('judges-edit')"
                @click="goToEditHandler(data.item.uuid)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">
                  {{ $t("edit") || "Edit" }}
                </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userCan('judges-activation_toggle')"
                @click="openDeletionWarning(data.item)"
              >
                <feather-icon
                  :icon="data.item.is_active ? 'ZapOffIcon' : 'ZapIcon'"
                />
                <span class="align-middle ml-50">{{
                  data.item.is_active
                    ? $t("deactivate") || "Deactivate"
                    : $t("activate") || "Activate"
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span v-if="judgesMeta" class="text-muted">
                {{ $t("total_entries", { total: judgesMeta.total }) }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-if="judgesMeta && judgesMeta.total"
                v-model="judgesMeta.current_page"
                :total-rows="judgesMeta.total"
                :per-page="judgesMeta.per_page"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePaginationHandler"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <UserForm
      id="user-form"
      :user="userToBeEdited"
      @hide="userToBeEdited = null"
    />
    <deleteWarning
      v-if="userCan('judges-activation_toggle')"
      :show="!!userToBeDeactivated"
      :is-loading="isDeleting"
      :action-label="
        userToBeDeactivated && userToBeDeactivated.is_active
          ? $t('deactivate') || 'Deactivate'
          : $t('activate') || 'Activate'
      "
      :cancel-label="$t('cancel') || 'Cancel'"
      :message="$t('are_you_sure') || 'Are You Sure?'"
      @close="cancelDeletionHandler"
      @delete="deactivateUserHandler"
    />

    <UserDetailsModal
      v-if="userDetails"
      id="user-details"
      :user="userDetails"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./partials/UsersListFilters.vue";

import UserForm from "./partials/UserForm.vue";
import useUsersList from "./useUsersList";
import deleteWarning from "@/components/UI/deleteWarning.vue";
import userCan from "@/mixins/UserCan";
import UserDetailsModal from "./partials/UserDetailsModal.vue";
import JudgesActors from "./judgeActors";

export default {
  components: {
    UsersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    UserForm,
    deleteWarning,
    UserDetailsModal,
  },
  data() {
    return {
      userToBeEdited: null,
      userToBeDeactivated: null,
      userDetails: null,

      // Pagination
      currentPage: 1,
      perPage: 10,
      totalUsers: 10,

      // Filters
      filters: {
        search: "",
        role: "",
        status: "",
      },
      searchQuery: "",
      roleFilter: "",
      statusFilter: "",
      planFilter: "",
      // Users list

      tableColumns: [
        {
          key: "user",
          label: this.$t("user") || "User",
        },
        { key: "roles", label: this.$t("roles") || "Roles" },
        {
          key: "actor",
          label: this.$t("actor") || "Actor",
        },
        { key: "status", label: this.$t("status") || "Status" },
        { key: "actions", label: this.$t("actions") || "Actions" },
      ],
      searchQueryTimer: null, // used for debounce search
      appliedFilters: {},
    };
  },
  computed: {
    judges() {
      return this.$store.state.judges.data;
    },
    judgesMeta() {
      return this.$store.state.judges.meta;
    },
    isLoading() {
      return this.$store.state.judges.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.judges.isLoading.deleting;
    },
  },
  created() {
    this.fetchUsersHandler();
    this.$store.dispatch("roles/fetchAllData");
    this.$store.dispatch("actors/fetchData");
  },
  methods: {
    userCan,
    getJudgeActor(judge) {
      if (!judge || !JudgesActors || !JudgesActors.length) return "-";
      return JudgesActors.find((actor) => actor.value == judge)?.label || "-";
    },
    openUserDetailsModal(user) {
      this.userDetails = user;
      setTimeout(() => {
        this.$bvModal.show("user-details");
      });
    },
    searchUsersHandler(searchText) {
      // debounce
      clearTimeout(this.searchQueryTimer);
      this.searchQueryTimer = setTimeout(() => {
        // if (!searchText) return;

        // detect if search text is phone or email or text
        const isPhone = /^\d+$/.test(searchText);
        const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          searchText
        );

        let searchQuery = "";

        if (isPhone) {
          searchQuery = { mobile: searchText };
          this.appliedFilters.email = undefined;
          this.appliedFilters.name = undefined;
        } else if (isEmail) {
          searchQuery = { email: searchText };
          this.appliedFilters.name = undefined;
          this.appliedFilters.mobile = undefined;
        } else {
          searchQuery = { name: searchText };
          this.appliedFilters.email = undefined;
          this.appliedFilters.mobile = undefined;
        }

        this.fetchUsersHandler(searchQuery);
      }, 300);
    },
    changePaginationHandler(page = 1) {
      this.fetchUsersHandler({ page });
    },
    fetchUsersHandler(params = null) {
      this.appliedFilters = {
        ...this.appliedFilters,
        page: 1, // reset page to 1 unless page param is passed underneath
        ...params,
      };
      this.$store.dispatch(
        "judges/fetchData",
        JSON.parse(JSON.stringify(this.appliedFilters))
      );
    },
    goToEditHandler(uuid) {
      this.$router.push({
        name: "edit-judge",
        query: { uuid },
      });
    },
    deactivateUserHandler() {
      this.$store
        .dispatch("judges/toggleActivation", {
          uuid: this.userToBeDeactivated.uuid,
          is_active: this.userToBeDeactivated.is_active ? 0 : 1,
        })
        .then(() => {
          this.userToBeDeactivated = null;
        });
    },
    openDeletionWarning(item) {
      this.userToBeDeactivated = item;
    },
    cancelDeletionHandler() {
      this.userToBeDeactivated = null;
    },
  },
  setup() {
    const {
      // UI
      resolveUserStatusVariant,
    } = useUsersList();

    return {
      avatarText,
      // UI
      resolveUserStatusVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
